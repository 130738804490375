import React from "react";
import "./EventCard.css";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import UserInfo from "../../../components/UserInfo";
import TimeLine from "../../../components/TimeLine";
import { VerticalTimeline } from "react-vertical-timeline-component";
import convertTimestampToDate from "../../../utils/convertTimestampToDate";

const findLowestTimestamp = (data) => {
  const arrayClone = [...data]
  return arrayClone.filter(date => date.timestamp).reduce((min, obj) => new Date(obj.timestamp) < new Date(min.timestamp) ? obj : min);
}

export default function EventCard({ event }) {
  const { items } = event
  return (
    <>
      <Accordion defaultActiveKey="1">
        <Card className={`event-card`}>
          <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-dark text-light">
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0"><span>{event.so}</span><br></br>{items[0].customerName}</p>
              <p className="mb-0">{convertTimestampToDate(findLowestTimestamp(items).timestamp)}</p>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Row className="d-flex justify-content-around">
                <UserInfo event={items[0]} className="sticky" />
                <Col lg={'8'} >
                  <VerticalTimeline
                    layout="1-column-right"
                    animate={false}
                    lineColor="black"
                    className="mr-2"
                  >
                    {items.sort((a, b) => {
                      if (a.timestamp === null && b.timestamp !== null) {
                        return 1;
                      }
                      if (a.timestamp !== null && b.timestamp === null) {
                        return -1;
                      }
                      if (a.timestamp === null && b.timestamp === null) {
                        return 0;
                      }
                      const timestampA = new Date(a.timestamp);
                      const timestampB = new Date(b.timestamp);
                      return timestampA - timestampB;
                    }).map((item, i) => (
                      <TimeLine key={i} event={item} />
                    ))}
                  </VerticalTimeline>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
}
