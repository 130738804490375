import React, { useState } from "react";
// import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import useApi from "../utils/useApi";
import "./CustomsSearchBox.css";
const CustomsSearchBox = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { searchHeader, submitHandler } = props;

  return (
    <div className="searchbox">
      <div className="left-side">
        <h3>{searchHeader}</h3>
        <textarea
          className="search-input-locator"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        ></textarea>
        <Button
          className="submit-btn"
          onClick={() => submitHandler(searchValue)}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default CustomsSearchBox;
