import { Accordion, Col, Row, Table } from "react-bootstrap";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import convertTimestampToDate from "../utils/convertTimestampToDate";

const OngoingOrders = ({ formatting, event }) => {
console.log(event);
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--education"
      contentStyle={{ background: formatting?.color, color: "black" }}
      contentArrowStyle={{ borderRight: "7px solid  #343a40" }}
      date={convertTimestampToDate(event?.timestamp)}
      iconStyle={{ background: formatting?.color }}
      layout={"1-column-right"}
      position={"right"}
    >
      <Row>
        <Col>
          <h3 className="vertical-timeline-element-subtitle">
            {formatting?.type}
          </h3>
          <p className="vertical-timeline-element-title mt-0">{event?.so}</p>
        </Col>
        <Col className="text-center">
          {event.transporter && <div>
            <p>{event.transporter.name}</p>
            {event.trackingData?.map((track, i) => {
              return track?.tracking?.trackingUrl ? <a key={i} className="link" href={track.tracking.trackingUrl} target="_blank" rel="noopener noreferrer" >{track.tracking.trackingUrl}</a> : track.parcelNumber
            }
            )}
          </div>}
        </Col>
      </Row>
      <hr></hr>
      {<Accordion>
        <Accordion.Toggle
          eventKey="0"
          className={`bg-${formatting.tableColor} text-light border-none`}
        >
          {"View Order Lines"}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0" className={"overflow-auto"}>
          <Table striped bordered hover variant={formatting?.tableColor} >
            <thead>
              <tr>
                <th className="centered">Sku</th>
                <th className="centered">Article Name</th>
                <th className="centered">Line Price</th>
                <th className="centered">Currency Code</th>
                <th className="centered">Picked Amount</th>
                <th className="centered">Ordered Amount</th>
              </tr>
            </thead>
            <tbody>
              {event.lines.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="centered">{item.article.articleNumber}</td>
                    <td className="centered">{item.article.articleName}</td>
                    <td className="centered">{item.prices.linePrice}.-</td>
                    <td className="centered">{item.prices.currencyCode}</td>
                    <td className="centered">{item.pickedNumberOfItems}</td>
                    <td className="centered">{item.orderedNumberOfItems}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Accordion.Collapse>
      </Accordion>}
    </VerticalTimelineElement>
  )
}

export default OngoingOrders
