import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "react-bootstrap";
import history from "./utils/history";
import Login from "./views/Login";
import Birdseye from "./views/birdseye/Birdseye";
import Locator from "./views/locator/Locator";
import Customs from "./views/customs/Customs";
import EanCheck from "./views/eancheck/Eancheck";
import PoImport from "./views/po-import/PoImport";
import WmsAdmin from "./views/wms-admin/WmsAdmin";
import SkuGenerator from "./views/sku-generator/SkuGenerator";
import Birdseye2 from "./views/birdseye_v2/Birdseye2";
import PricelistCheck from "./views/pricelist-check/Pricelist-Check";
import ImageUploader from "./views/image-uploader/ImageUploader";

import "./App.css";

import initFontAwesome from "./utils/initFontAwesome";
import NavBar from "./components/NavBar";
initFontAwesome();

const App = () => {
  const { isAuthenticated, loginWithRedirect, logout, isLoading, error } =
    useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {

    return (
      <Container className="d-flex align-items-center justify-content-center text-center vh-100" fluid={true}>
        <h1>Loading...</h1>
      </Container>
    );
  }
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  if (!isAuthenticated) {
    return <Login loginWithRedirect={loginWithRedirect} />;
  } else {
    return (
      <Router history={history}>
        <div id="app" className="backdrop">
          {isAuthenticated && (
            <div>
              <NavBar logoutWithRedirect={logoutWithRedirect} />
              <Container fluid className="mt-5">
                <Switch>
                  <Route exact path="/birdseye" component={Birdseye} />
                  <Route path="/birdseye2" component={Birdseye2} />
                  <Route path="/locator" component={Locator} />
                  <Route path="/customs" component={Customs} />
                  <Route path="/eancheck" component={EanCheck} />
                  <Route path="/poimport" component={PoImport} />
                  <Route path="/wmsadmin" component={WmsAdmin} />
                  <Route path="/skugenerator" component={SkuGenerator} />
                  <Route path="/pricelistcheck" component={PricelistCheck} />
                  <Route path="/imageuploader" component={ImageUploader} />
                </Switch>
              </Container>
            </div>
          )}
        </div>
      </Router>
    );
  }
};

export default App;
