import React from "react";
import "./EventCard.css";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { IoTimeSharp } from "react-icons/io5";
import BirdseyeEventWm from "./events/wm/BirdseyeEventWm";
import getPrintablePage from "../../utils/getPrintablePage";
import Dropdown from "react-bootstrap/Dropdown";


const formatDate = (input) => {
  const [date, time] = input.split("T");
  const [h, m] = time.split(":");
  return `${date} ${h}:${m}`;
};

export default function EventCard({ event }) {
  const typeColor = event.type === "wm_order" ? "success" : "danger";
  return (
    <Card className="event-card">
      <Card.Header>
        <Badge variant={typeColor}>{event.type}</Badge>{" "}
        <IoTimeSharp size={25} /> {formatDate(event.timestamp)}
        <Dropdown className="float-right">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Print
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => getPrintablePage(event, true)}>Norwegian</Dropdown.Item>
            <Dropdown.Item onClick={() => getPrintablePage(event, false)}>English</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body>
        <BirdseyeEventWm event={event} />
      </Card.Body>
    </Card>
  );
}
