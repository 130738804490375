import { Accordion, Col, Row, Table } from "react-bootstrap";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import convertTimestampToDate from "../utils/convertTimestampToDate";

const SalesCredit = ({ formatting, event, itemsWithDiscounts }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--education"
      contentStyle={{ background: formatting?.color, color: "black" }}
      contentArrowStyle={{ borderRight: "7px solid  #343a40" }}
      date={convertTimestampToDate(event?.timestamp)}
      iconStyle={{ background: formatting?.color }}
      layout={"1-column-right"}
      position={"right"}
    >
      <Row>
        <Col>
          <h3 className="vertical-timeline-element-subtitle">
            {formatting?.type}
          </h3>
          <p className="vertical-timeline-element-title mt-0">{event?.so}</p>
        </Col>
        <Col className="text-center">
        </Col>
      </Row>
      <hr></hr>
      {itemsWithDiscounts.length > 0 && <Accordion>
        <Accordion.Toggle
          eventKey="0"
          className={`bg-${formatting.tableColor} text-light border-none`}
        >
          {"View Order Lines"}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0" className={"overflow-auto"}>
          <Table striped bordered hover variant={formatting?.tableColor}>
            <thead>
              <tr>
                <th className="centered">Sku</th>
                <th className="centered">Size</th>
                <th className="centered" title="Sales price excluding mva">Ex. MVA</th>
                <th className="centered">Credit Amount EX. MVA</th>
                <th className="centered">Sales Price</th>
                <th className="centered">Credit Amount INC. MVA</th>
                <th className="centered">Original Price</th>
                <th className="centered">Quantity</th>
                <th className="centered">Description</th>
              </tr>
            </thead>
            <tbody>
              {itemsWithDiscounts.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="centered">{item.sku}</td>
                    <td className="centered">{item.itemVariantCode || '-'}</td>
                    <td className="centered">{item.exmva}.-</td>

                    <td className="centered">
                      {item.exmvaDiscount
                        ? `-${item.exmvaDiscount}.-`
                        : "0.-"}
                    </td>

                    <td className="centered">{item.incmva}.-</td>

                    <td className="centered">
                      {item.incmvaDiscount
                        ? `-${item.incmvaDiscount}.-`
                        : "0.-"}
                    </td>

                    <td className="centered">{item.price}.-</td>
                    <td className="centered">{item.quantity}</td>
                    <td className="centered">{item.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Accordion.Collapse>
      </Accordion>}
    </VerticalTimelineElement>
  )
}

export default SalesCredit
