import React, { useState } from "react";
import CustomsSearchBox from "./CustomsSearchBox.js";
import CallApi from "../../utils/useApi";
import SuperTable from "../../components/SuperTable";
import Card from "../../components/Card";
import { Col, Spinner } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const Customs = () => {
  const {getAccessTokenSilently } = useAuth0();
  const [orderlines, setOrderlines] = useState();
  const [totals, setTotals] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const customsSearchHandler = async (inOrders) => {
    setIsLoading(true);
    const orders = inOrders.split("\n").join("!!");
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "read:basic",
      });
      const res = await CallApi({
        method: "get",
        endpoint: `customs?orders=${orders}`,
        token: accessToken,
      });
      setOrderlines(res.mOrderLinesWithCategories);
      setTotals(res.totals);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Card>
        <Col>
          <CustomsSearchBox
            searchHeader={"Orders (one per line)"}
            submitHandler={customsSearchHandler}
          />
        </Col>
      </Card>
      {isLoading ? (
        <Spinner animation="border" variant="secondary" />
      ) : orderlines && orderlines.length ? (
        <SuperTable
          headers={[
            { name: "ERP" },
            { name: "SKU" },
            { name: "Name" },
            { name: "QTY inv." },
            { name: "NOK" },
            { name: "SEK" },
            { name: "EUR" },
            { name: "Date invoice" },
            { name: "Cat 2" },
            { name: "Cat 3" },
          ]}
          values={[
            ...orderlines.map((el) => {
              return [
                el.ERP,
                el.PRODID,
                el.PRODDESC,
                el.QTYINV,
                el.CURRENCY.includes("NOK") ? el.CURR_SUM : 0,
                el.CURRENCY.includes("SEK") ? el.CURR_SUM : 0,
                el.CURRENCY.includes("EUR") ? el.CURR_SUM : 0,
                el.DATEINVOICE,
                el.CATEGORY_2,
                el.CATEGORY_3,
              ];
            }),
            [
              "",
              "",
              "",
              "",
              'SUM NOK:\n' + parseFloat(orderlines
                .filter((el) => el.CURRENCY.includes("NOK"))
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.CURR_SUM,
                  0
                ).toFixed(2)),
                'SUM SEK:\n' + parseFloat(orderlines
                .filter((el) => el.CURRENCY.includes("SEK"))
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.CURR_SUM,
                  0
                ).toFixed(2)),
                'SUM EUR:\n' + parseFloat(orderlines
                .filter((el) => el.CURRENCY.includes("EUR"))
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.CURR_SUM,
                  0
                ).toFixed(2)),
              "",
              "",
              "",
            ],
          ]}
        />
      ) : (
        ""
      )}
      {totals && totals.length ? (
        <SuperTable
          headers={[
            { name: "Category" },
            { name: "Currency" },
            { name: "Sum" },
            { name: "Count" },
          ]}
          values={totals.map((el) => Object.values(el))}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Customs;
