import React from "react";
import "./DataPoint.css";

export default function CommentSection({ attribute, isEditing, handleAdminCommentChange }) {

  const handleContentChange = (e) => {
    handleAdminCommentChange(e)
  };

  return (
    <div className="data-point mb-2">
      {isEditing ? (
        <textarea
          value={attribute}
          onChange={handleContentChange}
          className="w-100 h-100 p-2"
        />
      ) : (
        <textarea className="w-100 h-100 p-2 border-0">{attribute}</textarea>
      )}
    </div>
  );
}