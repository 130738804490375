import React, { useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import DataPoint from "../../../../components/DataPoint";
import BirdseyeWmLines from "./BirdseyeWmLines";
import "./BirdseyeEventWm.css";
import CallApi from "../../../../utils/useApi";
import { useAuth0 } from "@auth0/auth0-react";
import CommentSection from "../../../../components/CommentSection";

export default function BirdseyeEventWm({ event }) {
  const { getAccessTokenSilently } = useAuth0();
  const { lines } = event;
  const [isEditing, setIsEditing] = useState(false);
  const [adminComment, setAdminComment] = useState(event.data.admincomment);

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = async ({ table, id, field, value }) => {
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    CallApi({
      method: "patch",
      endpoint: `birdseye/updateComment`,
      data: { table, id, field, value },
      token: accessToken,
    });
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setAdminComment(event.data.admincomment);
    setIsEditing(false);
  };

  const handleAdminCommentChange = (e) => {
    setAdminComment(e.target.value);
  };

  return (
    <div className="birdseye-event-kolonial">
      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Order Details</Card.Title>
              <Row>
                <Col>
                  <DataPoint title={"Order ID"} attribute={event.data.orderid} />
                  <DataPoint
                    title={"Customer name"}
                    attribute={event.data.customername}
                  />
                  <DataPoint title={"Customer email"} attribute={event.data.email} />
                  <DataPoint
                    title={"Phone"}
                    attribute={event.data.billingcontact_phone}
                  />
                </Col>
                <Col>
                  <DataPoint
                    title={"Shipping method"}
                    attribute={event.data.shippingmethod}
                  />
                  <DataPoint
                    title={"Address"}
                    attribute={event.data.billingcontact_address1}
                  />
                  <DataPoint
                    title={"City"}
                    attribute={event.data.billingcontact_city}
                  />
                  <DataPoint
                    title={"Country"}
                    attribute={event.data.shippingcontact_countryname}
                  />
                </Col>
                <Col>
                  <DataPoint
                    title={"Payment terms"}
                    attribute={event.data.paymentterms}
                  />
                  <DataPoint
                    title={"Shipping address"}
                    attribute={event.data.shippingcontact_address1}
                  />
                  <DataPoint
                    title={"Shipping city"}
                    attribute={event.data.shippingcontact_city}
                  />
                  <DataPoint
                    title={"Postal code"}
                    attribute={event.data.shippingcontact_zipcode}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100">
            <Card.Body className="d-flex flex-column">
              <Card.Title>Admin Comment</Card.Title>
              <CommentSection attribute={adminComment} isEditing={isEditing} handleAdminCommentChange={handleAdminCommentChange} />
              {isEditing ? (
                <div className="d-flex">
                  <Button variant="primary" className="w-25 mr-2" onClick={() => handleSaveClick({ table: event.table, id: event.data.orderid, field: "admincomment", value: adminComment })}>
                    Save
                  </Button>
                  <Button variant="secondary" className="w-25" onClick={handleCancelClick}>
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button variant="primary" className="w-25" onClick={handleEditClick}>
                  Edit
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {lines.length > 0 ? <BirdseyeWmLines lines={lines} /> : ""}
    </div>
  );
}
