import axios from "axios";

export default async function CallApi({
  method,
  endpoint,
  data,
  token,
  responseType = "json",
  additionalHeaders = {},
}) {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const url = `${baseUrl}${endpoint}`;
  
  let res;

  try {
    res = await axios({
      method,
      url,
      responseType,
      headers: {
        Authorization: `Bearer ${token}`,
        ...additionalHeaders,
      },
      ...(data && { data }),
    });
  } catch (e) {
    let errorMessage = e.toString();
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage += `\n${e.response.data.message}`;
    }
    alert(errorMessage);
    return false;
  }

  return res.data;
}
