import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner, Form, Button, Table } from "react-bootstrap";
import CallApi from "../../utils/useApi";
import Card from "../../components/Card";

const WmsAdmin = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [name, setName] = useState("");
  const [addedUsers, setAddedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    const res = await CallApi({
      method: "post",
      endpoint: `addwmsuser?name=${name.toLowerCase()}`,
      token: accessToken,
    });
    setAddedUsers([...addedUsers, res]);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Card>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              type="name"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            style={{ marginBottom: "5px" }}
          >
            Submit
          </Button>
          {isLoading ? (
            <Spinner animation="border" variant="secondary" />
          ) : addedUsers.length ? (
            <div>
              <h2>Nylig opprettede brukere:</h2>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Entrycreatedat</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {addedUsers.map((el) => (
                    <tr>
                      <td>{el.id}</td>
                      <td>{el.name}</td>
                      <td>{el.username}</td>
                      <td>{el.password}</td>
                      <td>{el.entrycreatedat}</td>
                      <td>{el.active.toString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            ""
          )}
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default WmsAdmin;
