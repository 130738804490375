import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Spinner, Form, Button } from "react-bootstrap";
import CallApi from "../../utils/useApi";
import Card from "../../components/Card";
import SuperTable from "../../components/SuperTable";

const PoImport = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [purchaseId, setPurchaseId] = useState("");
  const [products, setProducts] = useState("");
  const [importLog, setImportLog] = useState([]);
  const [importStatus, setImportStatus] = useState("Ready");
  const [isLoading, setIsLoading] = useState(false);

  let getLogPoller;

  const getImportLog = async ({ importId }) => {
    setIsLoading(true);

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    const res = await CallApi({
      method: "get",
      endpoint: `poimport-status?importId=${importId}`,
      token: accessToken,
    });

    console.log(res);
    setImportLog(res.data);
    setImportStatus(res.status);

    if (res.status === "done") {
      clearInterval(getLogPoller);
    }

    setIsLoading(false);
  };

  const submitHandler = async () => {
    setIsLoading(true);

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    const res = await CallApi({
      method: "post",
      endpoint: `poimport`,
      token: accessToken,
      data: {
        purchaseId,
        products,
      },
    });

    setIsLoading(false);

    getLogPoller = setInterval(
      () => getImportLog({ importId: res.importId }),
      2000
    );
  };

  return (
    <React.Fragment>
      <Card>
        <Col>
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Form.Label>Mamut purchase ID</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setPurchaseId(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Products to import</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                onChange={(e) => setProducts(e.target.value)}
              />
            </Form.Group>
            <Button onClick={submitHandler}>Import</Button>
          </Form>
          <h2 className="mt-3">Status: {importStatus}</h2>
        </Col>
        <Col>
          <h2>Instructions</h2>
          <ol>
            <li>
              Paste products to import into input box on the left as
              tab-separated values (copy from Excel / GS)
              <br />
              Syntax: SKU qty price
              <br />
              Example: ONM22-99861-8 2 693,90
            </li>
            <li>Click "import"</li>
            <li>Await status "done" or "failed"</li>
            <li>Verify all lines in table below, check for status "failed"</li>
            <li>In Mamut: Verify imported lines</li>
            <li>
              In Mamut: Add arbitrary line to the purchase, and then remove the
              line, to update the totals
            </li>
          </ol>
        </Col>
      </Card>

      {isLoading ? (
        <Spinner animation="border" variant="secondary" />
      ) : importLog.length ? (
        <SuperTable
          headers={[
            { name: "purchaseId" },
            { name: "prodid" },
            { name: "qty" },
            { name: "inprice" },
            { name: "id" },
            { name: "status" },
            { name: "errormessage" },
          ]}
          values={importLog.map((el) => Object.values(el))}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default PoImport;
