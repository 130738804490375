export default function convertTimestampToDate(timestamp) {
  if(!timestamp) {
    return 'No date found'
  }
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  const readableDate = `${month} ${day}, ${year}`;
  return readableDate;
}