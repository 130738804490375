import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Button, Spinner, Container, Row } from "react-bootstrap";
import CallApi from "../../utils/useApi";
import "./Birdseye.css";
import Card from "../../components/Card";
import EventCard from "./EventCard";

const Birdseye = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [search, setSearch] = useState("");
  const [searchType, setSearchType] = useState("name");
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });
    
    const res = await CallApi({
      method: "get",
      endpoint: `birdseye/events?search=${search}&type=${searchType}`,
      token: accessToken,
    });
    setEvents(res);
    setIsLoading(false);
  };

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Container>
          <Row>
            <Col xs={2}>
              <Form.Label>Search by</Form.Label>
              <Form.Control as="select" onChange={(e) => setSearchType(e.target.value)}>
                <option value="name">Name</option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
                <option value="orderNumber">Order Number</option>
              </Form.Control>
            </Col>
            <Col>
              <Form.Label>Search</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col className="my-2">
              <Button className="d-flex" onClick={submitHandler}>Search</Button>
            </Col>
          </Row>
        </Container>
      </Form>
      {isLoading ? (
        <Container className="d-flex justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </Container>
      ) : events && events.length ? (
        <Card>
          <Col>
            {events
              .map((el, i) => (
                <EventCard key={`${el.type}${i}`} event={el}></EventCard>
              ))}
          </Col>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default Birdseye;
