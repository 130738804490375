import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Col,
  Form,
  Button,
  Spinner,
  ButtonGroup,
  ToggleButton,
  Container,
} from "react-bootstrap";
import "./Birdseye.css";
import Card from "../../components/Card";
import CallApi from "../../utils/useApi";
import EventCard from "./events/EventCard";

function groupObjectsBySo(arr) {
  const grouped = [];

  arr.forEach(obj => {
    const soValue = obj.so;
    const existingGroup = grouped.find(group => group.so === soValue);

    if (existingGroup) {
      existingGroup.items.push(obj);
    } else {
      grouped.push({ so: soValue, items: [obj] });
    }
  });

  return grouped;
}

const Birdseye2 = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [query, setQuery] = useState("");
  const [radioValue, setRadioValue] = useState("1");
  const [salesOrders, setSalesOrders] = useState([]);
  const [salesInvoices, setSalesInvoices] = useState([]);
  const [salesCredits, setSalesCredits] = useState([]);
  const [ongoingOrders, setOngoingOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const radios = [
    { name: "SO Nr.", value: "1" },
    { name: "Name", value: "2" },
    { name: "Email", value: "3" },
    { name: "PhoneNumber", value: "4" }
  ];

  const birdseye2Api = async ({
    endpoint,
    accessToken,
    query,
    radioValue,
    setStateCallback,
  }) => {
    if (!query) return;
    try {
      const res = await CallApi({
        method: "get",
        endpoint: `birdseye2/${endpoint}?query=${query.trim()}&queryType=${radioValue}`,
        token: accessToken,
      });
      setStateCallback([...res]);
    } catch (e) { }
  };

  const submitHandler = async (e) => {
  try {
    e.preventDefault();
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    const promises = [
      birdseye2Api({
        endpoint: "getsalesorders",
        accessToken,
        query,
        radioValue,
        setStateCallback: setSalesOrders,
      }),
      birdseye2Api({
        endpoint: "getsalesinvoices",
        accessToken,
        query,
        radioValue,
        setStateCallback: setSalesInvoices,
      }),
      birdseye2Api({
        endpoint: "getsalescredits",
        accessToken,
        query,
        radioValue,
        setStateCallback: setSalesCredits,
      }),
      birdseye2Api({
        endpoint: "getongoingorders",
        accessToken,
        query,
        radioValue,
        setStateCallback: setOngoingOrders,
      })
    ];

    await Promise.all(promises);
  } catch (e) {
    console.log('\nError:', e, '\n')
  } finally {
    setIsLoading(false);
  }
};



  const concatArr = salesOrders
    .concat(salesInvoices)
    .concat(salesCredits)
    .concat(ongoingOrders);

  const combinedArray = groupObjectsBySo(concatArr)

  return (
    <React.Fragment >
      <Card >
        <Col className="d-flex justify-content-center">
          <Form onSubmit={submitHandler} className="d-flex flex-column">
            <ButtonGroup toggle className="w-100 mb-2">
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  type="radio"
                  variant="dark"
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => setRadioValue(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <Form.Control
              className="mb-2"
              type="text"
              onChange={(e) => setQuery(e.target.value)}
              autoFocus
            />
            <Button onClick={submitHandler} variant="dark">Search</Button>
          </Form>
        </Col>
      </Card>
      {isLoading ? (
        <Container fluid className="d-flex justify-content-center align-items-center justify-content-center mt-5">
          <Spinner animation="border" variant="dark" />
        </Container>
      ) : (
        <>
          <Card>
            <Col>
              {combinedArray.map(el => (
                <EventCard key={el.so} event={el} />
              ))}
            </Col>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

export default Birdseye2;