import React from "react";
import "./Login.css";
import { Button } from "react-bootstrap";

const Login = ({ loginWithRedirect }) => {
  return (
    <div className="login-container">
      <div className="login-content">
        <h1>Milrab Tools</h1>
        <Button variant="primary" size="lg" onClick={loginWithRedirect}>
          Log in
        </Button>
      </div>
    </div>
  );
};

export default Login;
