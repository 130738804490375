import latinize from "latinize";
import md5 from "blueimp-md5";

/**
 * hash()
 *
 * Generates a substring of the md5 hash of a string
 *
 * @param {string} s
 * @returns
 */

const hash = (s) => {
  const longHash = md5(s);
  const shortHash = longHash.substring(0, 5);
  return shortHash;
};

/**
 * normalize()
 *
 * Removes diacritics, vowels and spaces from string
 *
 * @param {string} s
 * @returns {string} normalized string
 */
const normalize = (s) => {
  s = s && s.length > 0 ? s : "0";
  s = latinize(s);
  s = s.replace(/[^a-zA-Z0-9]/g, "_");

  return s;
};

/**
 * skuGenerator()
 *
 * Our new SKUs has the following syntax:
 * M-B-A-C-S
 * M = Constant, designates that this is a new internal SKU
 * B = Brand code
 * A = Model (product) code
 * C = Color code (vendor color)
 * S = Size code
 * Example: M-BJRN-13E52-DGT-L
 *
 * @param {string} brand
 * @param {string} model
 * @param {string} color
 * @param {string} size
 * @returns {string} new internal sku
 */

const skuGenerator = (
  sku,
  brand,
  name,
  vendorColor,
  size,
  type,
  category,
  gender
) => {
  let product = "M-";
  product += normalize(brand).substring(0, 4);
  product += "-";
  product += hash(name + category + type + gender);
  product = product.toUpperCase();
  let item = product + "-" + hash(vendorColor ? vendorColor : " ");

  item = item.toUpperCase();

  let variant = item + "-" + normalize(size);
  return { product, item, variant, variantCode: normalize(size) };
};

const oldSkuGenerator = (brand, name, vendorColor) => {
  let oldProductFamily = `M-${normalize(brand).substring(0, 4)}-${hash(
    name
  )}-${normalize(vendorColor).substring(0, 4)}`;
  oldProductFamily = oldProductFamily.toUpperCase();
  return oldProductFamily;
};

const generateProductIds = (products) => {
  return products.map((product) => {
    if (!product.brand || !product.name) {
      return {
        product_id: product.id,
        internal_sku: { item: "ERROR", variantCode: "ERROR" },
      };
    }
    const internal_sku = skuGenerator(
      product.sku,
      product.brand,
      product.name,
      product.vendorColor,
      product.size,
      product.type,
      product.category,
      product.gender
    );

    const old_sku = oldSkuGenerator(
      product.brand,
      product.name,
      product.vendorColor
    );

    return {
      product_id: product.id,
      internal_sku,
      old_sku,
    };
  });
};

export default generateProductIds;
