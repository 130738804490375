import React from "react";
import Table from "react-bootstrap/Table";

function SuperTable({ headers, values }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th key={`super-table-${header}${i}`}>{header.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((val, i) => {
          return (
            <tr key={`super-table-row-${i}`}>
              {val.map((el, j) => (
                <td key={`super-table-${el}${j}`}>{el}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default SuperTable;
