import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Spinner, Form, Button } from "react-bootstrap";
import CallApi from "../../utils/useApi";
import Card from "../../components/Card";
import SuperTable from "../../components/SuperTable";
import './Pricelist-Check.css';

const PoImport = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [itemId, setItemId] = useState("");
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault()
    setIsLoading(true);

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    const res = await CallApi({
      method: "get",
      endpoint: `checkbcwsiitems/${itemId}`,
      token: accessToken,
    });

    setResponse(res);

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Card>
        <Col>
          <Form onSubmit={submitHandler}>
            <Form.Group>
              <Form.Label>Item ID</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setItemId(e.target.value)}
              />
            </Form.Group>

            <Button onClick={submitHandler}>Check</Button>
          </Form>
        </Col>
        <Col></Col>
      </Card>

      {isLoading ? (
        <Spinner animation="border" variant="secondary" />
      ) : response.item ? (
        <>
          <SuperTable
            headers={[
              { name: "itemNo" },
              { name: "description" },
              { name: "quantityForSale" },
              { name: "changedAt" },
              { name: "osbWSIPriceChangedAt" },
              { name: "osbWSIInventoryChangedAt" },
            ]}
            values={[Object.values(response.item)]}
          />
          <div className="price-list-container">
            <SuperTable
              headers={[
                { name: "priceListCode" },
                { name: "priceListDescription" },
                { name: "price" },
                { name: "currencyCode" },
                { name: "competitorPriceUpdated" },
                { name: "competitorUnitPrice" },
                { name: "competitorPriceTypeDescription" },
                { name: "competitorName" },
                { name: "systemCreatedAt" },
                { name: "systemModifiedAt" },
                { name: "statusDescription" },
                { name: "startingDate" },
                { name: "endingDate" },

              ]}
              values={response.wsiPriceLists.map((el) => Object.values(el))}
            />
          </div>
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default PoImport;
