import { renderToString } from "react-dom/server";
import PrintableReceipt from "../components/PrintableReceipt";


const getPrintablePage = (event, isNorwegian) => {
  const newTab = window.open('', '_blank');
  newTab.document.write(`<html>
  <head>
  <title>Printable Receipt</title>
  <style>
  body {
    font-family: Tahoma, Verdana, Arial, sans-serif;
  }
</style>
  </head><body>`
  );
  
  // Render the PrintableReceipt component into the new tab
  const printableReceipt = <PrintableReceipt data={event.data} lines={event.lines} isNorwegian={isNorwegian} />;
  const printableReceiptHtml = renderToString(printableReceipt);
  newTab.document.write(printableReceiptHtml);
  
  newTab.document.write('</body></html>');
  newTab.document.close();
};

export default getPrintablePage;