import React from "react";
import Badge from "react-bootstrap/Badge";

import "./DataPoint.css";

export default function DataPoint({ title, attribute, badge, isEditing, handleAdminCommentChange }) {

  const handleContentChange = (e) => {
    handleAdminCommentChange(e)
  };

  return (
    <div className="data-point mb-2">
      <p className="datapt-title">
        <strong>{title}</strong>
      </p>
      {badge ? (
        <Badge color="blue" variant={badge}>
          {attribute}
        </Badge>
      ) : (
        isEditing ? (
          <textarea
            value={attribute}
            onChange={handleContentChange}
            className="w-100 h-100 p-2"
          />
        ) : (
          <p>{attribute}</p>
        )
      )}
    </div>
  );
}