import React from "react";
import { useDrag, useDrop } from "react-dnd";
import UploadedImage from "./UploadedImage.js";

function SortableItem({ item, index, uploadedFiles, setUploadedFiles }) {
  // eslint-disable-next-line no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    type: "BOX",
    item: () => ({ index, name: item.name, lastModified: item.lastModified }),
  });

  const deleteImage = (files, setFiles, el) => {
    setFiles(
      files.filter(
        (f) => f.name !== el.name && f.lastModified !== el.lastModified
      )
    );
  };

  const [, drop] = useDrop({
    accept: "BOX",
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;
      if (draggedIndex === targetIndex) {
        return;
      }

      // make a copy of uploadedfiles excluding the item at index "index"
      let newUploadedFiles = uploadedFiles.filter(
        (f) => f.name !== item.name && f.lastModified !== item.lastModified
      );

      let movedItem = uploadedFiles.find(
        (f) => f.name === item.name && f.lastModified === item.lastModified
      );
      // insert the item at index "draggedIndex" into the new array, shifting the rest of the array
      newUploadedFiles.splice(targetIndex, 0, movedItem);
      setUploadedFiles(newUploadedFiles);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={(node) => drag(drop(node))} style={{ width: "100%" }}>
      <UploadedImage
        file={item}
        i={index}
        deleteImage={() => {
          deleteImage(uploadedFiles, setUploadedFiles, item);
        }}
      />
    </div>
  );
}

export default SortableItem;
