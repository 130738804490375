import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import "./NavBar.css";

const NavBar = ({ logoutWithRedirect }) => {
  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/home">Milrab</Navbar.Brand>
      <Nav className="mr-auto" /*activeKey="/birdseye"*/>
        <LinkContainer to="/birdseye">
          <Nav.Link>Birdseye</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/birdseye2">
          <Nav.Link>Birdseye 2.0</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/locator">
          <Nav.Link>Locator</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/customs">
          <Nav.Link>Customs</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/eancheck">
          <Nav.Link>EAN Check</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/poimport">
          <Nav.Link>PO import</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/wmsadmin">
          <Nav.Link>WMS Admin</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/skugenerator">
          <Nav.Link>SKU generator</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/pricelistcheck">
          <Nav.Link>Pricelist check</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/imageuploader">
          <Nav.Link>Image Uploader</Nav.Link>
        </LinkContainer>
        {/* <Nav.Link onSelect={() => console.log("logou")}>Log Out</Nav.Link> */}
      </Nav>
      <Nav>
        <Button onClick={logoutWithRedirect}>Log out</Button>
      </Nav>
    </Navbar>
  );
};

export default NavBar;
