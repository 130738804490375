import React from "react";
import "./BirdseyeWmLines.css";
export default function BirdseyeWmLines({ lines }) {
  return (
    <div className="birdseye-wm-lines">
      <h2>Orderlines</h2>
      <table className="w-100">
        <tbody>
          <tr className="wm-line-hdr-row">
            <th className="wm-line-value">SKU</th>
            <th className="wm-line-value-wide">Description</th>
            <th className="wm-line-value">Price</th>
            <th className="wm-line-value">Qty. ordered</th>
            <th className="wm-line-value">Snapstock</th>
          </tr>
          {lines.map((line, i) => (
            <tr className="wm-line-row" key={`wm-line-${i}`}>
              <td className="wm-line-value">{line.brandsku}</td>
              <td className="wm-line-value-wide">
                {`${line.brandname}  ${line.productname}`}
              </td>
              <td className="wm-line-value">{line.sellingprice}</td>
              <td className="wm-line-value">{line.qty}</td>
              <td className="wm-line-value">{line.snapstock}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
