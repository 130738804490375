import React from "react";
import trashIcon from "../../assets/trash.svg";

function UploadedImage({ file, i, deleteImage }) {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-around",
        }}
      >
        <img src={trashIcon} alt="trash" width="60px" onClick={deleteImage} />
        <h1>{i + 1}.</h1>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img width={"100px"} src={URL.createObjectURL(file)} alt="uploaded" />
        <p>{file.name}</p>
      </div>
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}></div>
    </div>
  );
}

export default UploadedImage;
