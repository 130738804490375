import React from "react";
import { Button } from "react-bootstrap";

import "./DataPoint.css";

export default function ItemStatus({ item }) {
  const showDetailsHandler = () => {
    let msg = ``;
    let variantMsgs = ``;
    let attributeMsgs = ``;
    if (item.completeness === "Success") return;
    if (!item.success) {
      msg = `Item failed to upload. Variant and attribute uploads were cancelled. \n See error details below and consider reporting to DEV: \n ${item.errorMsg}`;
    } else {
      variantMsgs = item.variants
        .map((variant) => {
          return `Variant Code "${variant.variant}": ${
            variant.success
              ? "Success"
              : `failed with the following error message: "${variant.errorMsg}"`
          }`;
        })
        .join("\n");
      attributeMsgs = item.attributes
        .map((attribute) => {
          return `${attribute.attributeName}: ${
            attribute.success ? "Success" : attribute.errorMsg
          }`;
        })
        .join("\n");
      msg = `Item uploaded successfully, but not all attributes and variants. \n See details below and consider reporting to DEV:  \n\n Variants: \n ${variantMsgs} \n\n Attributes \n ${attributeMsgs}`;
    }
    alert(msg);
  };
  return (
    <div>
      <p>{item?.completeness}</p>
      {item?.completeness !== "Success" && item?.completeness ? (
        <Button onClick={showDetailsHandler}>Se detaljer</Button>
      ) : (
        ""
      )}
    </div>
  );
}
