/* eslint-disable eqeqeq */
import React, { useState } from "react";
// import fileDownload from "js-file-download";
// import { useAuth0 } from "@auth0/auth0-react";
import { Col, Form, Button } from "react-bootstrap";
import CallApi from "../../utils/useApi";
import Card from "../../components/Card";
import SuperTable from "../../components/SuperTable";
import ItemStatus from "../../components/ItemStatus";
import generateProductIds from "../../utils/generateSku";

const SkuGenerator = () => {
  const [generatedSkus, setGeneratedSkus] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [collisions, setCollisions] = useState([]);
  const [products, setProducts] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [bcStatus, setBcStatus] = useState("");
  const [bcResults, setBcResults] = useState([]);
  const [ignoredCollisions, setIgnoredCollisions] = useState([]);

  const handleClickSkuGenerator = async () => {
    try {
      setGeneratedSkus([]);
      setIsWaiting(true);
      setBcStatus("");
      setBcResults([]);
      setCollisions([]);
      setIgnoredCollisions([]);

      const parsedProducts = products.split("\n").map((product) => {
        if (product.split("\t").length === 11) {
          alert(
            "Feil format på input. Kopier fra GS og lim inn i tekstfeltet."
          );
        }

        const [
          id,
          brand,
          vendorNo,
          name,
          vendorColor,
          realColor,
          size,
          type,
          category,
          gender,
          ean,
          costprice,
          salesprice
        ] = product.split("\t");
        return {
          id,
          brand,
          vendorNo,
          name,
          vendorColor,
          realColor,
          size,
          type,
          category,
          gender,
          ean,
          costprice,
          salesprice
        };
      });

      const ids = parsedProducts.map((el) => el.id);

      if (ids.filter((item, index) => ids.indexOf(item) != index).length > 0) {
        alert(
          `
          Alle linjer må ha unikt leverandørnr.!
          Følgende leverandørnr. forekommer flere ganger:
          ${Array.from(
            new Set(ids.filter((item, index) => ids.indexOf(item) != index))
          ).join(",")}`
        );
        setIsWaiting(false);
        return;
      }

      const result = generateProductIds(parsedProducts);
      const resultWithMeta = result.map((el) => {
        return {
          ...el,
          meta: parsedProducts.find((p) => p.id === el.product_id),
        };
      });
      setIsWaiting(false);
      setGeneratedSkus(resultWithMeta);
    } catch (e) {
      console.log(e);
      setIsWaiting(false);
      setGeneratedSkus([]);
    }
  };

  const handleClickCheckSkuExists = async () => {
    try {
      setIsWaiting(true);
      setCollisions([]);
      const res = await CallApi({
        method: "post",
        endpoint: "checkskuexists",
        data: generatedSkus,
      });

      const skusWithCollisionStatus = generatedSkus
        .map((el) => {
          const hasNewSkuCollision = res.newSkuCollisions?.some(
            (coll) => coll === el.internal_sku.item
          );

          const oldskucollisionssku = res.oldSkuCollisions.find(
            (coll) => coll.newSku === el.internal_sku.item
          );
          const eanCollisionssku = res.eanCollisions.find((coll) => {
            return coll["ean"] === el.meta.ean.replace(/^0+/, "");
          });

          const nameCollisions = res.nameCollisions.find(
            (coll) => coll.name === el.meta.name
          )?.items;

          return {
            ...el,
            collision:
              hasNewSkuCollision || oldskucollisionssku || eanCollisionssku,
            has_new_sku_collision: hasNewSkuCollision,
            old_sku_collision: oldskucollisionssku?.oldSku || "",
            ean_collision: eanCollisionssku ? eanCollisionssku.items : false,
            name_collision: nameCollisions?.length ? nameCollisions : [],
          };
        })
        .sort((el) => (el.collision ? -1 : 1))
        .sort((el) => (el.old_sku_collision ? -1 : 1));

      setGeneratedSkus(skusWithCollisionStatus);
      setCollisions(res);
      setIsWaiting(false);
      alert("Kollisjoner sjekket - se tabell");
    } catch (e) {
      console.log(e);
      setIsWaiting(false);
    }
  };

  const handleClickValidate = async () => {
    setIsWaiting(true);
    const res = await CallApi({
      method: "post",
      endpoint: "validateskugen",
      data: generatedSkus,
    });

    if (res.numberOfErros > 0) {
      alert(
        ` Items with invalid/new category:\n ${res.invalidCategoryItems.join(
          "\n"
        )}
          Items with invalid/new gender:\n ${res.invalidGenderItems.join("\n")}
          Items with invalid/new type:\n ${res.invalidTypeItems.join("\n")}`
      );
    }

    const missingCostprice = generatedSkus.filter(
      (el) => el.meta.costprice === ""
    );
    if (missingCostprice.length > 0) {
      alert(
        `Items with missing costprice that must be fixed before continuing:\n ${missingCostprice
          .map((el) => el.product_id)
          .join("\n")}`
      );
    }

    const validColors = [
      "Svart",
      "Beige/natur",
      "Blå",
      "Brun",
      "Kamuflasje",
      "Gull",
      "Grønn",
      "Grå",
      "Khaki",
      "Metall",
      "Flerfarget",
      "Oransje",
      "Rosa",
      "Lilla",
      "Rød",
      "Sølv",
      "Gjennomsiktig",
      "Hvit",
      "Gul",
      "Marineblå",
      "Multicam",
      "Olivengrønn",
      "Sort",
      "Coyote",
      "", // ingen farge
      "Multi",
      "Grå",
      "Sølv",
      "Gull",
    ];
    const invalidColors = generatedSkus.filter(
      (el) => !validColors.includes(el.meta.realColor)
    );
    if (invalidColors.length > 0) {
      alert(
        `Items with invalid real color that must be fixed before continuing:\n ${invalidColors
          .map((el) => el.product_id)
          .join("\n")}`
      );
    }

    const missingSalesprice = generatedSkus.filter(
      (el) => el.meta.salesprice === ""
    );
    if (missingSalesprice.length > 0) {
      alert(
        `Items with missing salesprice that must be fixed before continuing:\n ${missingSalesprice
          .map((el) => el.product_id)
          .join("\n")}`
      );
    }

    const tooLongVendorSkus = generatedSkus.filter(
      (el) => el.product_id.length > 20
    );
    if (tooLongVendorSkus.length > 0) {
      alert(`Items with too long vendor sku:\n ${tooLongVendorSkus
        .map((el) => el.product_id)
        .join("\n")}
      They will be autmatically truncated to 20 characters`);
      setGeneratedSkus([
        ...generatedSkus.map((el) => ({
          ...el,
          product_id: el.product_id.slice(0, 20),
        })),
      ]);
    }
    setIsWaiting(false);
  };

  const handleClickExportToBC = async () => {
    try {
      setIsWaiting(true);
      setBcStatus("Waiting..");
      const res = await CallApi({
        method: "post",
        endpoint: "exportskuimportbc",
        data: generatedSkus.filter(
          (el) =>
            !el.collision || ignoredCollisions.includes(el.internal_sku.item)
        ),
      });
      setIsWaiting(false);
      setBcStatus("Done");
      setBcResults(res);
      alert("Done");
    } catch (e) {
      setIsWaiting(false);
      setBcResults([]);
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Col>
          <Form onSubmit={handleClickSkuGenerator}>
            <Form.Group>
              <Form.Label>Lim inn fra NPR template</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                onChange={(e) => setProducts(e.target.value)}
              />
            </Form.Group>
            <Button
              disabled={isWaiting ? true : false}
              onClick={handleClickSkuGenerator}
            >
              1. Lag SKU
            </Button>
            <Button
              disabled={isWaiting ? true : false}
              onClick={handleClickValidate}
              className="ml-2"
            >
              2. Validèr SKU
            </Button>
            <Button
              disabled={isWaiting ? true : false}
              className="ml-2"
              onClick={handleClickCheckSkuExists}
            >
              3. Sjekk for kollisjoner
            </Button>
            <Button
              disabled={isWaiting ? true : false}
              className="ml-2"
              onClick={handleClickExportToBC}
            >
              4. Eksport til BC
            </Button>
          </Form>
          {isWaiting ? (
            <img
              src={
                "https://media.tenor.com/rec5dlPBK2cAAAAd/mr-bean-waiting.gif"
              }
              alt="loading..."
            />
          ) : null}
        </Col>
        <Col>
          <h2>Instruksjoner</h2>
          <ol>
            <b>
              <li>
                Fyll inn{" "}
                <a
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/1rDMi10YHlQ6eTEkutxZz0vrCIzUq76bx5xsL-HEXSwQ/edit#gid=1786850796"
                  rel="noreferrer"
                >
                  SKU generator template
                </a>
              </li>
            </b>
            <li>
              <b>Lim inn rader fra template i tekstfeltet og trykk "Lag SKU"</b>
            </li>
            <li>
              <b>Klikk "Validèr SKU"</b> for å validere at informasjonen er i
              riktig format
            </li>
            <li>
              <b>Klikk "Sjekk for kollisjoner".</b> Kollisjoner vil markeres i
              kolonnen "Eksisterer i BC", med detaljer i kolonnen "Detaljer".
              Kollisjoner på "Oldsku" kan ignoreres. I kolonnen
              "Navnekollisjoner" er sku med identisk navn - disse bør undersøkes
              da det kan være samme produkt, men f.eks lagt inn med annen
              kategori. Alle SKU som eksisterer fra før i BC må legges inn
              manuelt (det er sannsynligvis snakk om å legge til varianter til
              eksisterende varenummer). SKU som eksisterer fra før i BC (ref
              denne oversikten) vil ikke inkluderes i importen.
            </li>
            <li>
              <b>Klikk "Eksport til BC".</b> Status-kolonnen vil fortelle om
              importen er ferdig, og om den feilet, lyktes delvis, eller lyktes
              fullstendig. Dersom importen lyktes delvis har èn eller flere
              varianter eller attributter feilet.
            </li>
            <li>
              <b>Pt. må importen godkjennes manuelt i BC.</b>
            </li>
          </ol>
        </Col>
      </Card>

      {generatedSkus.length ? (
        <SuperTable
          headers={[
            { name: "Leverandørnr."},
            { name: "Lev. varenr." },
            { name: "Brand" },
            { name: "Kategori" },
            { name: "Kjønn" },
            { name: "Produktnavn" },
            { name: "Type" },
            { name: "Lev. farge" },
            { name: "EAN" },
            { name: "Felles varenr" },
            { name: "Variantkode" },
            { name: "Eksisterer i BC?" },
            { name: "Detaljer" },
            { name: "Navnekollisjoner" },
            { name: "Status" },
          ]}
          values={generatedSkus.map((el) => {
            return [
              el.meta.vendorNo,
              el.product_id,
              el.meta.brand,
              el.meta.category,
              el.meta.gender,
              el.meta.name,
              el.meta.type,
              el.meta.vendorColor,
              el.meta.ean,
              el.internal_sku.item,
              el.internal_sku.variantCode,
              el.collision ? "JA" : "",
              <div>
                <div>
                  {el.old_sku_collision ? (
                    <div>
                      <p>Kollisjon på oldSku</p>
                      {ignoredCollisions.includes(el.internal_sku.item) ? (
                        <>
                          <s>{el.old_sku_collision}</s>
                          <p style={{ color: "red" }}>Kollisjon ignorert</p>
                          <button
                            onClick={() =>
                              setIgnoredCollisions([
                                ...ignoredCollisions.filter(
                                  (collSku) => collSku != el.internal_sku.item
                                ),
                              ])
                            }
                          >
                            U-Ignorèr
                          </button>
                        </>
                      ) : (
                        <>
                          <p>{el.old_sku_collision}</p>
                          <button
                            onClick={() =>
                              setIgnoredCollisions([
                                ...ignoredCollisions,
                                el.internal_sku.item,
                              ])
                            }
                          >
                            Ignorèr
                          </button>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
                <div>
                  {el.ean_collision ? (
                    <div>
                      <p>Kollisjon på EAN</p>
                      {JSON.stringify(el.ean_collision)}
                    </div>
                  ) : null}
                </div>
                <div>
                  {el.has_new_sku_collision ? (
                    <div>
                      <p>Kollisjon på Newsku</p>
                    </div>
                  ) : null}
                </div>
              </div>,
              el.name_collision?.length ? (
                <div>
                  <Button
                    variant="warning"
                    onClick={() => {
                      alert(
                        `${el.name_collision.map((el) => `${el}\n`).join("")}`
                      );
                    }}
                  >
                    Se sku med samme navn
                  </Button>
                </div>
              ) : (
                ""
              ),
              bcStatus === "Done" ? (
                <ItemStatus
                  item={
                    bcResults.length &&
                    bcResults?.find(
                      (result) => result.item === el.internal_sku.item
                    )
                  }
                ></ItemStatus>
              ) : el.collision &&
                !ignoredCollisions.includes(el.internal_sku.item) ? (
                ""
              ) : (
                bcStatus
              ),
            ];
          })}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SkuGenerator;
