import React from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";

const UserInfo = ({ event }) => {
  return (
    <>
      <Col lg={"auto"} className="mr-4">
        <Tabs defaultActiveKey="shipping" className="mb-2">
          <Tab eventKey="shipping" title="Shipping">
            <p>Name: {event.customerName}</p>
            <p>Email: {event.email}</p>
            <p>Phone: {event.phoneNumber}</p>
            <p>Address: {event.shippingAddressLine1}</p>
            {event.shippingAddressLine2 && (
              <p>Address2: {event.shippingAddressLine2}</p>
            )}
            <p>City: {event.shippingCity}</p>
            <p>Zip Code: {event.shippingPostalCode}</p>
          </Tab>
          <Tab eventKey="billing" title="Billing">
            <p>Name: {event.billingName}</p>
            <p>Email: {event.email}</p>
            <p>Phone: {event.phoneNumber}</p>
            <p>Address: {event.billingAddressLine1}</p>
            {event.billingAddressLine2 && (
              <p>Address2: {event.billingAddressLine2}</p>
            )}
            <p>City: {event.billingCity}</p>
            <p>Zip Code: {event.billingPostalCode}</p>
          </Tab>
        </Tabs>
        <hr></hr>
        <Row lg={"auto"}>
          <Col>
            {event.shipping_method && (
              <p>
                Shipping:{" "}
                {event.shipping_method[0][0] +
                  event.shipping_method[0].slice(1)}
              </p>
            )}
            {event.payment_method && (
              <p>
                Payment:{" "}
                {event.payment_method[0][0] + event.payment_method[0].slice(1)}
              </p>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default UserInfo;
