/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import addIcon from "../../assets/plus-square-dotted.svg";
import SortableItem from "./SortableItem.js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useAuth0 } from "@auth0/auth0-react";
import CallApi from "../../utils/useApi";
import "./ImageUploader.css";
import { useDropzone } from "react-dropzone";

function ImageUploader() {
  const { getAccessTokenSilently } = useAuth0();
  const [searchSku, setSearchSku] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [chosenSku, setChosenSku] = useState({});
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".gif", ".webp", ".svg"],
    },
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles]);
    },
  });

  useEffect(() => {
    if (files.length === 0) return;
    handleFileEvent({ target: { files: files } });
  }, [files]);

  useEffect(() => {
    (async function () {
      if (searchSku.length > 5) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:basic",
        });
        const res = await CallApi({
          method: `get`,
          endpoint: `pimgetsku?sku=${searchSku}`,
          token: accessToken,
        });
        setSearchResults(res.data);
      } else {
        setSearchResults([]);
      }
    })();
  }, [searchSku]);

  const submitSkuHandler = async ({ sku, entityId }) => {
    if (!sku || !entityId) {
      alert("Please select a sku");
      return;
    }
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    const formData = new FormData();
    uploadedFiles.forEach((file, i) => {
      formData.append("files", file, file.name);
    });
    formData.append("data", JSON.stringify({ entityId }));

    const res = await CallApi({
      method: `post`,
      endpoint: `pimuploadmedia`,
      token: accessToken,
      data: formData,
      additionalHeaders: {
        "Content-Type": "multipart/form-data",
      },
    });

    alert(`
    ${res.filter((el) => el.status === "success").length} files uploaded
    ${res.filter((el) => el.status === "failure").length} files failed:
    ${res
      .filter((el) => el.status === "failure")
      .map((el) => el.name)
      .join(", ")}
    `);
    setUploadedFiles([
      ...uploadedFiles.filter(
        (el) => !res.map((l) => l.name).includes(el.name)
      ),
    ]);
  };

  const handleFileEvent = (e) => {
    if (e.target.files) {
      const newUploadedFiles = [...uploadedFiles];
      const chosenFiles = Array.prototype.slice.call(e.target.files);
      chosenFiles.forEach((file) => {
        if (
          uploadedFiles.find(
            (f) => f.name === file.name && f.lastModified === file.lastModified
          )
        ) {
          alert(`File ${file.name} already uploaded`);
          return;
        } else {
          file.id = file.name + file.lastModified;
          newUploadedFiles.push(file);
        }
      });
      setUploadedFiles(newUploadedFiles);
      setFiles([]);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          width: "700px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          height: "auto",
          // height: "1000px",
          padding: "30px",
        }}
      >
        <InputGroup className="input-group">
          {chosenSku.sku ? (
            <InputGroup.Text style={{}}>{chosenSku.sku}</InputGroup.Text>
          ) : (
            <Form.Control
              aria-label="Sku input"
              onChange={(e) => setSearchSku(e.target.value)}
            />
          )}

          <InputGroup.Text
            onClick={() => {
              setSearchResults([]);
              setChosenSku({});
            }}
          >
            Clear sku
          </InputGroup.Text>
        </InputGroup>
        {searchResults?.length > 0 && !chosenSku.sku && (
          <div className="clickable-link-container">
            {searchResults.map((item) => (
              <div
                key={item.id}
                className="clickable-link"
                onClick={() => {
                  setChosenSku(item);
                  setSearchSku(item.sku);
                }}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>{item.sku}</a>
              </div>
            ))}
          </div>
        )}
        <DndProvider backend={HTML5Backend}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <img src={addIcon} width="200px" alt="add" />
          </div>
        </DndProvider>
        <DndProvider backend={HTML5Backend}>
          <div style={{ marginTop: "20px", width: "100%" }}>
            {uploadedFiles.map((item, index) => {
              return (
                <SortableItem
                  key={item.id}
                  item={item}
                  index={index}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                />
              );
            })}
          </div>
        </DndProvider>
        <Button
          onClick={() => {
            submitSkuHandler(chosenSku);
          }}
        >
          Yeet to cyberspace 🚀
        </Button>
        <p>{`Selected sku: ${chosenSku?.sku || "NOT SELECTED"}`}</p>
      </Card>
    </div>
  );
}

export default ImageUploader;
