import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SearchBox from "../../components/SearchBox";
import SuperTable from "../../components/SuperTable";
import Card from "../../components/Card";
import { Col, Spinner } from "react-bootstrap";
import CallApi from "../../utils/useApi";
import "./Eancheck.css";

const Eancheck = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [eans, setEans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const eancheckSubmitHandler = async (eans) => {
    setIsLoading(true);
    const searchString = eans.split("\n").join("!!");

    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "read:basic",
      });
      const res = await CallApi({
        method: "get",
        endpoint: `eancheck?ean=${searchString}`,
        token: accessToken,
      });
      setEans(res);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Col>
          <SearchBox
            preHeaderText={
              "If no rows are returned after submit, EANs does not exist in database"
            }
            searchHeader={"Eancheck"}
            submitHandler={eancheckSubmitHandler}
          />
        </Col>
      </Card>
      {isLoading ? (
        <Spinner animation="border" variant="secondary" />
      ) : eans && eans.length ? (
        <Card>
          <SuperTable
            headers={[{ name: "Prodid" }, { name: "Eancode" }]}
            values={eans.map((el) => Object.values(el))}
          />
        </Card>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Eancheck;
