import React from "react";
import OngoingOrders from "./OngoingOrders";
import "react-vertical-timeline-component/style.min.css";
import "./TimeLine.css";
import SalesCredit from "./SalesCredit";
import SalesInvoice from "./SalesInvoice";
import SalesOrder from "./SalesOrder";

const VerticalTimeLine = ({ event }) => {
  const { lines, transaction_id, vippsorder, type } = event;
  let transactionId;
  if (transaction_id) {
    const uniqueTransactionIds = new Set(
      transaction_id.filter((item) => item !== "")
    );
    transactionId = [...uniqueTransactionIds];
  }
  let itemsWithDiscounts = [];
if (lines && type !== "ongoingOrders") {
  itemsWithDiscounts = lines.reduce((result, item) => {
    if (item.exmva < 0) {
      const matchingItem = lines.find(
        ({ so, sku, exmva }) => so === item.so && sku === item.sku && exmva > 0
      );

      if (matchingItem) {
        const exmvaDifference = item.exmva + matchingItem.exmva;
        const incmvaDifference = item.incmva + matchingItem.incmva;

        matchingItem.exmvaDiscount = exmvaDifference;
        matchingItem.incmvaDiscount = incmvaDifference;
      }
    } else if (item.incmva >= 0 && item.exmva >= 0) {
      result.push(item);
    }
    return result;
  }, []);
}

  let formatting = {};
  if (event) {
    switch (type) {
      case "salesOrder":
        formatting = {
          type: "Sales Order",
          color: "#FFD966",
          tableColor: "warning",
        };
        return <SalesOrder formatting={formatting} event={event} itemsWithDiscounts={itemsWithDiscounts} transactionId={transactionId} vippsorder={vippsorder}/>;
      case "salesInvoice":
        formatting = {
          type: "Sales Invoice",
          color: "#6ECB63",
          tableColor: "success",
        };
        return <SalesInvoice formatting={formatting} event={event} itemsWithDiscounts={itemsWithDiscounts} transactionId={transactionId} vippsorder={vippsorder}/>;

      case "salesCredit":
        formatting = {
          type: "Sales Credit",
          color: "#FF4444",
          tableColor: "danger",
        };
        return <SalesCredit formatting={formatting} event={event} itemsWithDiscounts={itemsWithDiscounts}/>;
      case "ongoingOrders":
        formatting = {
          type: "Ongoing Shipment",
          color: "#2196F3",
          tableColor: "primary",
        };
        return <OngoingOrders formatting={formatting} event={event}/>;
      default:
        event.color = "#607D8B";
        break;
    }
  }
  return <h1>Contact IT</h1>
};

export default VerticalTimeLine;
