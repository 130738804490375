import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Spinner } from "react-bootstrap";
import CallApi from "../../utils/useApi";
import Card from "../../components/Card";
import SuperTable from "../../components/SuperTable";
import SearchBox from "../../components/SearchBox";

import "./Locator.css";

const Locator = () => {
  const {getAccessTokenSilently } = useAuth0();

  const [locs, setLocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const locatorSearchHandler = async (skus) => {
    setIsLoading(true);
    const sku = skus.split("\n").join("!!");

    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "read:basic",
    });

    const res = await CallApi({
      method: "get",
      endpoint: `locator?sku=${sku}`,
      token: accessToken,
    });

    setLocs(res);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Card>
        <Col>
          <SearchBox
            searchHeader={"SKUs (One per line)"}
            submitHandler={locatorSearchHandler}
          />
        </Col>
      </Card>

      {isLoading ? (
        <Spinner animation="border" variant="secondary" />
      ) : locs && locs.length ? (
        <SuperTable
          headers={[
            { name: "Sku" },
            { name: "Description" },
            { name: "Location" },
            { name: "Stock" },
            { name: "Cost Price" },
          ]}
          values={locs.map((el) => Object.values(el))}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default Locator;
