import React from 'react'

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return formattedDate;
};

const formatNumber = (number) => {
  return number.toLocaleString('no-NO', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}



const PrintableReceipt = ({ data, lines, isNorwegian }) => {
  const toggleLang = isNorwegian;
  return (
    <>
      <table width="600" border="0" cellPadding="0" cellSpacing="0" align="center">
        <tbody>
          <tr>
            <td>
              <table width="100%" border="0" cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr valign="top">
                    <td width="50%" nowrap="">
                      <b>Milrab</b><br />
                      <small>
                        Milrab.no<br />
                        Toveien 28, 1540 Vestby<br />
                        {toggleLang ? 'Telefonnr: 53 69 78 78' : 'Phone: 53 69 78 78'}<br />
                        Org nr: 997 595 262
                      </small>
                    </td>
                    <td align="right">
                      <b>{toggleLang ? 'Ordrenummer' : 'Ordre Number'}</b><br />
                      <small>
                        <b>#{data.orderid}</b>
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <table border="0" width="100%">
                <tbody>
                  <tr valign="top">
                    <td width="50%" align="right" nowrap="">
                      <b>{toggleLang ? 'Sendt' : 'Sent'}</b>&nbsp;
                    </td>
                    <td width="50%">{formatDate(data.shipdate)}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table width="100%" border="1" bordercolor="#c0c0c0" cellPadding="1" cellSpacing="0">
                <tbody>
                  <tr valign="top">
                    <td width="50%">
                      <table border="0" cellSpacing="0" width="100%">
                        <tbody>
                          <tr align="center">
                            <td colSpan="2" bgcolor="#e3e3e3">
                              <b>{toggleLang ? 'Faktureringsadresse' : 'Billing Address'}</b>
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              {toggleLang ? <b>Navn:</b> : <b>Name:</b>}&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.billingcontact_firstname} {data.billingcontact_lastname}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Adresse:' : 'Address:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.billingcontact_address1}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Sted:' : 'City:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.billingcontact_zipcode} {data.billingcontact_city}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Land:' : 'Country:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.shippingcontact_countryname}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Telefonnr:' : 'Phone Number:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.billingcontact_phone}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="50%">
                      <table border="0" cellSpacing="0" width="100%">
                        <tbody>
                          <tr align="center">
                            <td colSpan="2" bgcolor="#e3e3e3">
                              <b>{toggleLang ? 'Leveringsadresse' : 'Delivery Address'}</b>
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Navn' : 'Name'}:</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.shippingcontact_firstname} {data.shippingcontact_lastname}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Adresse:' : 'Address:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.shippingcontact_address1}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Sted:' : 'City:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.shippingcontact_zipcode} {data.shippingcontact_city}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Land:' : 'Country:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.shippingcontact_countryname}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Telefonnr:' : 'Phone Number:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.shippingcontact_phone}
                            </td>
                          </tr>
                          <tr>
                            <td nowrap="" align="right">
                              <b>{toggleLang ? 'Epost:' : 'Email:'}</b>&nbsp;
                            </td>
                            <td width="100%">
                              &nbsp;{data.email}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <table width="100%" border="1" bordercolor="#c0c0c0" cellPadding="1" cellSpacing="0">
                <tbody>
                  <tr>
                    <td>
                      <table width="100%" border="0" cellPadding="1" cellSpacing="0">
                        <tbody>
                          <tr>
                            <td width="50%" nowrap="" align="right">
                              <b>{toggleLang ? 'Betalingsbetingelser' : 'Payment Terms'}:</b>&nbsp;
                            </td>
                            <td width="50%">
                              &nbsp;{data.paymentterms}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <table width="100%" border="1" bordercolor="#c0c0c0" cellPadding="0" cellSpacing="0">
                <tbody><tr><td>
                  <table width="100%" border="0" cellPadding="2" cellSpacing="1">
                    <tbody><tr align="center" bgcolor="#e3e3e3">
                      <td width="100%"><b>{toggleLang ? 'Produkt/Produsent' : 'Product/Manufacturer'}</b></td>
                      <td><b>{toggleLang ? 'Antall' : 'Quantity'}</b></td>
                      <td><b>{toggleLang ? 'Pris' : 'Price'}</b></td>
                      <td><b>{toggleLang ? 'Totalt' : 'Total'}</b></td>
                    </tr>
                      <tr ><td colSpan="5" bgcolor="#c0c0c0" height="2"></td></tr>
                      {lines.map((line, index) => (
                        <React.Fragment key={index}>
                          <tr key={line.brandsku}>
                            <td width="100%">{line.brandame} {line.productname} ({line.brandsku})</td>
                            <td align="center">{line.qty}</td>
                            <td align="right" nowrap="">{formatNumber(line.sellingprice)}</td>
                            <td align="right" nowrap="">{formatNumber(line.qty * line.sellingprice)}</td>
                          </tr>
                          <tr><td colSpan="5" bgcolor="#c0c0c0" height="2"></td></tr>
                        </React.Fragment>
                      ))}

                      {data.discount > 0 && <tr align="right" bgcolor="#f3f3f3">
                        <td colSpan="3"><b>{toggleLang ? 'Avslag' : 'Discount'}</b> ({data.discountcouponcode})<b>:</b>&nbsp;</td>
                        <td width="15%" colSpan="1" nowrap=""><b>{data.discount}</b></td></tr>}
                      <tr align="right" bgcolor="#f3f3f3">
                        <td colSpan="3"><b>{toggleLang ? 'Eks. mva' : 'Ex. VAT'}:</b>&nbsp;</td>
                        <td width="15%" colSpan="1" nowrap=""><b>1&nbsp;{formatNumber(data.subtotal)}</b></td></tr>
                      <tr align="right" bgcolor="#f3f3f3">
                        <td colSpan="3"><b>{toggleLang ? 'Frakt/omkostninger' : 'Freight/Costs'}</b> ({data.shippingmethod})<b>:</b>&nbsp;</td>
                        <td width="15%" colSpan="1" nowrap=""><b>{formatNumber(data.shippingsum)}</b></td></tr>
                      <tr align="right" bgcolor="#f3f3f3">
                        <td colSpan="3"><b>{toggleLang ? 'Mva:' : 'VAT:'}&nbsp;</b></td>
                        <td width="15%" colSpan="1" nowrap=""><b>{formatNumber(data.mva)}</b></td></tr>
                      <tr align="right" bgcolor="#f3f3f3">
                        <td colSpan="3"><b>{toggleLang ? 'Totalt inkl. mva:' : 'Total incl. VAT'}</b>&nbsp;</td>
                        <td width="15%" colSpan="1" nowrap=""><b>1&nbsp;{formatNumber(data.total)}</b></td></tr>
                    </tbody>
                  </table>
                </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <table width="100%" cellSpacing="0" cellPadding="2"></table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default PrintableReceipt